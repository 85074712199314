.footer{
  width:100%;
  box-sizing:border-box;
  display:flex;
  align-items:center;
  justify-content:space-between;
  color:white;
  font-size:16px;
  bottom:0px;
  position:absolute;
  padding-left:75px;
  padding-right:75px;
  box-sizing:border-box;
  padding-bottom:15px;
  padding-top:55px;
}

.footer img{
  height:30px;
}
