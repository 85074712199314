.howto-top{
  padding-top:101px;
}

.howto-title{
  font-family: 'Haettenschweiler';
  font-style: normal;
  font-weight: 500;
  font-size: 120px;
  line-height: 90%;
  color: #FFFFFF;
  margin-top:24px;
  margin-bottom:24px;
}

.howto-subtitle{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #39F376;
  padding-bottom:175px;
  border-bottom: 4px solid #272727;
}

.howto-section{
  display:flex;
  justify-content:space-between;
  align-items:flex-start;
  width:100%;
  padding-bottom:80px;
  border-bottom: 4px solid #272727;
  padding-top:80px;
}

.howto-img{
  width:344px;
  height:308px;
}

.howto-section-right{
  width:536px;
}

.howto-section-title{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 100%;
  color: #FFFFFF;
  margin-bottom:8px;
}

.howto-section-blurb{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
}

.howto-section-blurb a{
  text-decoration:none;
}

.howto-green-subtitle{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #39F376;
  margin-top:32px;
  margin-bottom:8px;

}

.howto-grey-instruction-box{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 24px;
  position: static;
  width: 100%;
  background: #141414;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  margin-top:32px;
}

.howto-instruction-box-title{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #FFFFFF;
}

.howto-instruction-hr{
  background-color:rgba(255, 255, 255, 0.2);
  width:100%;
  height:4px;
  margin-top:24px;
  margin-bottom:24px;
}

.howto-instruction{
  display:flex;
  justify-content:space-between;
  margin-bottom:24px;
  width:100%;
}

.howto-instruction-number{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #39F376;
}

.howto-instruction-right{
  width: 449px;
}

.howto-instruction-title{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  margin-bottom:8px;
}

.howto-instruction-text{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #CCCCCC;
}
