.owner-number{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  color: #39F376;
}

.owner-nft-name{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 41px;
  color: #FFFFFF;
  margin-top:8px;
  margin-bottom:8px;
}

.owner-right-bottom-row{
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:100%;
}

.owner-owned-by{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}

.owner-solscan{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  text-decoration-line: underline;
  color: #BBBBBB;
}

.owner-right-side{
  width:536px;
  padding:40px;
  border: 3px solid #39F376;
  border-radius: 30px;
  position:relative;
  height:494px;
  box-sizing:border-box;
  padding-top:336px;
  margin-left:104px;
}

.owner-img{
  width:262px;
  height:400px;
  position:absolute;
  left:137px;
  top:-94px;
}

.perks-are-redeemable{
  margin-top:24px;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #BBBBBB;
}

.mint-success-totast{
  width:536px;
  display:flex;
  align-items:center;
  height: 54px;
  background: #DFF1E6;
  border: 1px solid #218947;
  border-radius: 10px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #218947;
  padding-left:17px;
  position:absolute;
  bottom: -80px;
}
