.login-button{
  padding:10px;
  border:1px solid black;
  cursor:pointer;
  width:150px;
  margin-bottom:30px;
}

.login-page input{
  height:30px;
  font-size:15px;
  padding:5px;
}

.login-submit{
  padding:10px;
  border:1px solid black;
  width:150px;
  margin-top:15px;
  background-color:green;
}

.login-page{
  padding:30px;
}
