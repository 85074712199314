.header{
  height:114px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  padding-left:75px;
  padding-right:75px;
  box-sizing:border-box;
  width:100%;
}

.friends-logo{
  height:50px;
}
