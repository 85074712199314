.loading-page{
  width:100%;
  height:100vh;
  background-color:black;
  display:flex;
  align-items:center;
  justify-content:center;
}

.mint-page{
  background-color:black;
  width:100%;
  min-height:calc(100vh);
  box-sizing:border-box;
  display:flex;
  flex-direction:column;
  align-items:center;
}

.grid-background{
  background-image: url('https://cdn.hngr.co/grid100.png');
}

.mint-page-content{
  width:1280px;
}

.mint-page-top-content{
  width:100%;
  display:flex;
  box-sizing:border-box;
  align-items:center;
  justify-content:space-between;
  padding-left:142px;
  padding-right:142px;
}

.mint-page-top-content-left{
  height:100%;
  display:flex;
  flex-direction:column;
  padding-right:100px;
  box-sizing:border-box;
}

.mint-page-top-content-left-top-row-x{
  margin-left:35px;
  margin-right:35px;
}


.mint-page-top-content-right-img{
  width:380px;
  height:550px;
}

.mint-page-top-content-left-top-row{
  display:flex;
  align-items:center;
  justify-content:space-between;
  padding-top:15px;
}


.mint-page-top-content-left-top-supper-club{
  font-weight: 500;
  font-size: 57px;
  line-height: 61px;
  color: #FFFFFF;
  margin-left:10px;
  font-family: 'Haettenschweiler';
}

.mint-page-top-content-blurb{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #FFFFFF;
}

.mint-green-bar-content{
  height:100%;
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:100%;
}

.green-bar-countdown-section{
  display:flex;
  align-items:center;
}

.green-bar-countdown-section-left{
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  color: #39F376;
  align-items:flex-end;
  padding-right:20px;
}

.green-bar-countdown-section-left-top{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.green-bar-countdown-section-left-bottom{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.countdown-clock{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  color: #39F376;
  margin-left:15px;
}

.mint-button{
  cursor:pointer;
  height: 59px;
  background: #39F376;
  box-shadow: 2px 4px 0px #B69562;
  border-radius: 31px;
  display:flex;
  align-items:center;
  justify-content:center;
  padding-left:20px;
  padding-right:20px;
  color:black;
}

.mint-green-bar{
  z-index:9;
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  height:90px;
  position:fixed;
  background-color:black;
  bottom:0px;
  color: #39F376;
  border-top: 4px solid #39F376;
  box-sizing:border-box;
  padding-left:20px;
  padding-right:20px;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align:center;
}


.mint-page-above-the-fold{
  width:100%;
  display:flex;
  align-items:center;
  flex-direction:column;
  box-sizing:border-box;
  height:calc(100vh - 100px -90px);
}

.mint-page-top-content-image{
  /*height:calc(100vh - 100px - 90px - 60px);
  box-sizing:border-box;
  */
  width:344px;
  height:440px;
}

.mint-date{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */
  color: #39F376;
  display:flex;
  align-items:center;
  justify-content:center;
  height:68px;
  background: rgba(235, 195, 92, 0.3);
  border-radius: 20px;
  padding:0px 20px;
}

.mint-page-section{
  width:996px;
  margin-bottom:50px;
  padding:40px;
  background: rgba(20, 20, 20, 0.6);
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  backdrop-filter: blur(15px);
  border-radius: 20px;
}

.mint-space-between{
  display:flex;
  align-items:center;
  justify-content:space-between;
  flex-direction:row;
}

.mint-space-between-column{
  display:flex;
  align-items:center;
  justify-content:space-between;
  flex-direction:column;
}

.mint-section-thirds{
  width:33%;
  box-sizing:border-box;
  padding:0px 30px;
}

.mint-section-thirds img{
  width:360px;
  height:300px;
}

.mint-section-yellow-subtitle-text{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  display: flex;
  text-align: center;
  color: #39F376;
  line-height: 100%;
  margin-bottom:20px;

}

.mint-page-section *{
  stroke:#39F376 !important;
}

.mint-section-small-text{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color:white;
}

.mint-page-story-container{
  display:flex;
  width:100%;
} 

.mint-page-story-container-left{
  box-sizing:border-box;
  width:calc(100% - 500px);
  padding-top:40px;
  padding-bottom:40px;
  padding-left:40px;
  padding-right:40px;
}

.mint-page-story-container-img{
  width:500px;
  height:500px;
}

.our-story-logo{
  height:58px;
  margin-top:20px;
}

.num-minted{
  font-family: 'Haettenschweiler';
  font-style: normal;
  font-weight: 500;
  font-size: 100px;
  line-height: 107px;
  /* identical to box height */
  display: flex;
  align-items: center;
  color: #39F376;
}

.available-now{
  margin-top:20px;
  margin-bottom:15px;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 47px;
  color: #FFFFFF;
}

.mint-page-mint-container{
  display:flex;
  width:100%;
  height:100%;
}

.mint-page-mint-container-right{
  padding-left:70px;
  padding-top:30px;
}

.mint-page-huge-text{
  font-family:'Haettenschweiler';
  font-style: normal;
  font-weight: 500;
  font-size: 120px;
  line-height: 128px;
  color: #FFFFFF;
}


.cooking-up-your-nft{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 118px;
  display: flex;
  align-items: center;
  color: #39F376;
}

.mint-page-above-fold-title{
  font-family: 'Haettenschweiler';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 51px;
  color: #BBBBBB;
}

.mint-page-above-fold-title-huge{
  font-family: 'Haettenschweiler';
  font-style: normal;
  font-weight: 500;
  font-size: 110px;
  line-height: 80%;
  color: #FFFFFF;
  margin-bottom:16px;
}



.mint-page-above-fold-blurb{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  margin-bottom:24px;
}

.mint-page-green-button {
  text-decoration:none;
}

.mint-page-green-button{
  display:flex;
  align-items:center;
  justify-content:center;
  padding-left:20px;
  padding-right:20px;
  height: 59px;
  background: #39F376;
  box-shadow: 2px 4px 0px #079E38;
  border-radius: 30px;
  box-sizing:border-box;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  cursor:pointer;
}

.generic-green-button:hover{
  background: #5AFF90;
  box-shadow: 2px 4px 0px #079E38;
}

.mint-page-green-button:hover{
  background: #5AFF90;
  box-shadow: 2px 4px 0px #079E38;
}

.mint-section-green-button:hover{
  background: #5AFF90;
  box-shadow: 2px 4px 0px #079E38;
}

.mint-page-black-button{
  display:flex;
  align-items:center;
  justify-content:center;
  padding-left:20px;
  padding-right:20px;
  height: 59px;
  color: #39F376;
  border: 2px solid #39F376;
  border-radius: 30px;
  box-sizing:border-box;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  cursor:pointer;
  text-decoration:none;
}


.mint-page-black-button:hover{
  border: 2px solid #FFFFFF;
  color:white;
}


.mint-page-black-button:hover *{
  fill:white;
}

.mint-page-buttons-container{
  display:flex;
  align-items:center;
  width:100%;
}

.mint-page-top-content-outer{
  height:calc(100vh - 100px - 90px);
  display:flex;
  align-items:center;
}

.mint-page-green-tag{
  display:flex;
  align-items:center;
  justify-content:center;
  font-family: 'Space Mono';
  white-space:nowrap;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #39F376;
  padding: 0px 24px;
  height: 59px;
  background: #114923;
  border-radius: 10px;
}

.green-font{
  color:#39F376;
}

.mint-section-flex{
  display:flex;
  justify-content:space-between;
}

.blueburger{
  width: 340px;
  height: 384px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
}

.fatburger-photo-vert{
  width: 340px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
}

.mint-section-header{
  color:white;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 100%;
  margin-bottom:24px;
}

.mint-section-header-centered{
  width:100%;
  text-align:center;
  margin-bottom:40px;
}

.emojisection-left{
  width:536px;
}


.mint-section-blurb{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

.mint-section-buttons-container{
  display:flex;
  margin-top:24px;
}

.mint-section-green-button{
  text-decoration:none;
  display:flex;
  align-items:center;
  justify-content:center;
  height: 56px;
  background: #39F376;
  box-shadow: 2px 4px 0px #079E38;
  padding:0px 24px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  border-radius: 30px;
  cursor:pointer;
}

.mint-section-black-button{
  text-decoration:none;
  height: 56px;
  background: #000000;
  border: 2px solid #39F376;
  box-sizing: border-box;
  border-radius: 30px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #39F376;
  padding:0px 24px;
  cursor:pointer;
}


.mint-section-black-button:hover{
  border: 2px solid #FFFFFF;
  color:white;
}

.mint-section-black-button:hover *{
  fill:white;
}

.grey-banner-thing{
  width:100%;
  display:flex;
  justify-content:center;
  background: #141414;
  height:102px;
  margin-bottom:80px;
}

.grey-banner-inner{
  width:950px;
  display:flex;
  justify-content:center;
  align-items:center;
  background: #141414;
  height:102px;
}

.grey-banner-text{
font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 41px;
  /* identical to box height */
  color: #FFFFFF;
}

.mint-section-three-pics{
  display:flex;
  justify-content:space-between;
}

.mint-section-three-pic-container{
  background-color:black;
  width: 272px;
  display:flex;
  align-items:center;
  flex-direction:column;
  border-radius:10px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing:border-box;
  padding:16px;
}

.mint-section-three-pic-container img{
  width:240px;
  height:200px;
}

.mint-section-three-pic-green-text{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #39F376;
  margin-top:20px;
  margin-bottom:8px;
}

.mint-section-three-pic-grey-text{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #BBBBBB;
}


@media only screen and (max-height: 800px){
  .mint-page-above-fold-title-huge{
    font-size:100px;
  }

  .mint-page-top-content-image{
    width:297px;
    height:430px;
  }

  .mint-page-above-fold-blurb{
    font-size:16px;
  }
}


@media only screen and (max-height: 700px){
  .mint-page-above-fold-title-huge{
    font-size:70px;
  }

  .mint-page-top-content-image{
    height:300px;
    width:207px;
  }

  .mint-page-above-fold-blurb{
    font-size:14px;
  }
}

.mobile-container{
  width:100%;
  min-height:100vh;
  background-color:black;
}

.mobile-header{
  z-index:99;
  width:100%;
  height:75px;
  background-color:black;
  display:flex;
  justify-content:center;
  position:sticky;
  top:0px;
  align-items:center;
  padding-left:24px;
  box-sizing:border-box;
}

.mobile-title{
  font-family: 'Haettenschweiler';
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 100%;
  color: #FFFFFF;
  margin-top:34px;
  margin-bottom:24px;
}

.mobile-blurb{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom:24px;
}

.mobile-content{
  box-sizing:border-box;
  padding:0px 24px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  padding-bottom:80px;
}

.mobile-green-button{
  height: 56px;
  background: #39F376;
  box-shadow: 2px 4px 0px #079E38;
  border-radius: 30px;
  padding:0px 24px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.mobile-black-button{
  height: 56px;
  background: #000000;
  /* Brand/Supper Club Green */
  border: 2px solid #39F376;
  box-sizing: border-box;
  border-radius: 30px;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #39F376;
  display:flex;
  align-items:center;
  justify-content:center;
}

.mint-page-top-tag-row{
  width:100%;
  display:flex;
  align-items:center;
  margin-bottom:16px;
}

.top-tag-row-title{
  white-space:nowrap;
  font-style: normal;
  font-family: 'Space Grotesk';
  font-weight: 400;
  font-size: 30px;
  line-height: 80%;
  color: #BBBBBB;
}

.top-tag{
  margin-left:24px;
  display:flex;
  justify-content: center;
  white-space:nowrap;
  align-items: center;
  padding: 0px 24px;
  height: 59px;
  background: rgba(57, 243, 118, 0.3);
  border-radius: 10px;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #39F376;
}

.top-tag-grey{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  height: 59px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: justify;
  color: #FFFFFF;
  margin-left:24px;
}

.green-bar-open-tag{
  display:flex;
  align-items:center;
  justify-content:center;
  height: 48px;
  background: rgba(57, 243, 118, 0.3);
  border-radius: 4px;
  padding:0px 16px;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #39F376;
  margin-left:16px;
}

.generic-green-button{
  text-decoration:none;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:0px 24px;
  height:52px;
  background: #39F376;
  box-shadow: 2px 4px 0px #079E38;
  border-radius: 30px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  cursor:pointer;
}

.generic-black-button{
  display:flex;
  align-items:center;
  justify-content:center;
  padding:0px 24px;
  height:52px;
  background: black; 
  border-radius: 30px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #39F376;
  cursor:pointer;
  border: 2px solid #39F376;
  text-decoration:none;
}

.generic-green-button-wallet-container img{
  /*display:none;*/
}

.generic-green-button-wallet-container .wallet-adapter-button-trigger{
  padding:0px;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:0px 24px;
  height:52px;
  background: #39F376;
  box-shadow: 2px 4px 0px #079E38;
  border-radius: 30px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  cursor:pointer;
}

.generic-green-button-wallet-container .wallet-adapter-button-start-icon{
  display:none;
  margin:0px;
}


.generic-green-button-wallet-container .wallet-adapter-button-trigger:hover{
  background-color:#39F376;
}

.mobile-banner-warning{
  width:100%;
  height:66px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  padding: 16px 24px;
  background: #EBC35C;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: #000000;
  box-sizing:border-box;
  position:sticky;
  top:75px;
  z-index:99;
}

.mobile-banner-warning svg{
  height:31px;
  width:31px;
  margin-right:12px;
}

.mobile-page-content-container{
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
}

.mobile-landing-page-content{
  width:323px;
}

.top-subtitle-mobile{
  white-space:nowrap;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 80%;
  color: #BBBBBB;
  margin-top:38px;
  margin-bottom:8px;
}

.title-mobile{
  font-family: 'Haettenschweiler';
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 80%;
  color: #FFFFFF;
}

.mobile-img-container{
  display:flex;
  width:100%;
  justify-content:center;
}

.mobile-img-container img{
  width:323px;
  margin-top:16px;
  margin-bottom:16px;
}

.mobile-landing-blurb{
  width:322px;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom:16px;
}



.mobile-sections-container{
  display:flex;
  flex-direction:column;
  justify-content:center;
  margin-bottom:100px;
  margin-top:40px;
}

.mobile-landing-section{
  background: rgba(20, 20, 20, 0.6);
  padding: 24px 16px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  backdrop-filter: blur(15px);
  border-radius: 20px;
  margin-bottom:32px;
}

.mobile-landing-section-title{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 51px;
  color: #FFFFFF;
}

.mobile-landing-img{
  width: 327px;
  height: 354px;
  margin-top:16px;
  margin-bottom:16px;
}


@media only screen and (max-width: 340px){
  .mobile-img-container img{
    width:250px;
    height:320px;
  }

  .mobile-landing-img{
      width:250;
      height:226px;
  }
}

.unsupported-banner-desktop{
  width:100%;
  height: 64px;
  background: #EBC35C; 
  display:flex;
  align-items:center;
  justify-content:center;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: #000000;
  position:fixed;
  z-index:99;
  top:100px;
}

.green-bar-sold-out{
  height: 52px;
  background: rgba(196, 196, 196, 0.3);
  border-radius: 4px;
  padding:0px 16px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #BBBBBB;
}

.mint-four-boxes{
  padding: 24px 30px;
  height: 132px;
  box-sizing:border-box;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display:flex;
  align-items:flex-start;
  justify-content:space-between;
}

.mint-four-boxes-box{
  width: 190px;
  height: 84px;
  box-sizing:border-box;
  background: rgba(0, 0, 0, 0.2);
  border: 2px solid #39F376;
  border-radius: 10px;
  padding:8px 0px;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-between;
}

.mint-four-boxes-box-title{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
}


.mint-four-boxes-box-subtitle{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 41px;
  color: #FFFFFF;
}

.mint-howto{
  width:100%;
  display:flex;
  justify-content:space-between;
  position:relative;
}

.mint-howto-left{
  width:340px;
  position:sticky;
  top:130px;
  height:100%;
}

.metaverse-complicated{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #BBBBBB;
}

.we-know{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 100%;
  color: #FFFFFF;
  margin-top:24px;
  margin-bottom:24px;
}

.dont-see{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #39F376;
  margin-bottom:16px;
}

.mobile-section-title{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  text-align: center;
  color: #FFFFFF;
  width:100%;
  margin-bottom:16px;
}

.mobile-mint-details-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 50px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.mobile-mint-section-detail{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  background: rgba(0, 0, 0, 0.2);
  border: 2px solid #39F376;
  border-radius: 10px;
  box-sizing:border-box;
  width: 227px;
  height: 84px;
  margin-bottom:24px;
}

.mobile-mint-detail-top{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
}

.mobile-mint-detail-bottom{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 41px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
}

.mobile-fat-image{
  width:327px;
}

.fatburger-mobile-blurb{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-top:16px;
  margin-bottom:24px;
  width:323px;
}

.mobile-green-button{
  padding: 16px 24px;
  width: 327px;
  height: 52px;
  background: #39F376;
  box-shadow: -2px 4px 0px #079E38;
  border-radius: 30px;
  box-sizing:border-box;
  display:flex;
  align-items:center;
  justify-content:center;
  text-decoration:none;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.mobile-black-button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  width: 327px;
  height: 52px;
  background: #000000;
  border: 2px solid #39F376;
  display:flex;
  align-items:center;
  justify-content:center;
  text-decoration:none;
  border-radius: 30px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #39F376;

}

.mobile-footer{
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-top:32px;
}

.mobile-footer-link{
  text-decoration:none;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}
