.mint-header{
  width:100%;
  box-sizing:border-box;
  background-color:black;
  height:100px;
  display:flex;
  align-items:center;
  justify-content:center;
  position:fixed;
  top:0px;
  z-index:99;
}

.mint-header-inner{
  height:100px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  box-sizing:border-box;
}

.mint-padding{
  padding-left:120px;
  padding-right:120px;
}

.mint-header-left{
  display:flex;
  align-items:center;
  height:100%;
  font-family: 'Haettenschweiler';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 50px;
  color: #FFFFFF;
}

.mint-header-right{
  display:flex;
  align-items:center;
  height:100%;
}

.mint-header-about{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  padding-left:10px;
  padding-right:10px;
  cursor:pointer;
  color:white;
  margin-right:25px;
}

.mint-header-about:hover{
  text-decoration:underline;
}

.mint-header-link{
  text-decoration:none;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  cursor:pointer;
  margin-right:32px;
}

.mint-header-link:hover{
  color:#39F376;
}


.mint-header-link:hover *{
  fill:#39F376;
}


.mint-header-right  .wallet-adapter-button-trigger{
  padding:0px;
  box-shadow:none !important;
  text-align:center;
  box-sizing: border-box;
  cursor:pointer;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #39F376;
  background-color:black;
}


.mint-header-right  .wallet-adapter-button-trigger:hover{
}

.mint-wallet-header-connect-button{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #39F376;
  padding: 0px 24px;
  height: 36px;
  border: 2px solid #39F376;
  border-radius: 10px;
  box-sizing:border-box;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
}

.mint-wallet-header-connect-button:hover{
  color:white;
  border-color:white;
}

.hide-i i {
  display:none !important;
}

.wallet-adapter-button.hide-i{
  padding:0px;
}

.phantom-connected-button{
  height: 49px;
  background: #4D43CD;
  border-radius: 6px;
  display:flex;
  box-sizing:border-box;
  align-items:center;
  justify-content:center;
  padding:0px 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  font-family: 'Space Grotesk';
}

.phantom-connected-button svg{
  margin-right:12px;
}
