.tutorial-left{
  width:calc(50% - 30px);
  padding-top:30px;
}

.tutorial-right{
  width:calc(50% - 30px);
  padding-top:30px;
}

.tutorial-question{
  color:#FFFFFF80;
  font-weight:600;
  font-size:18px;
}


.tutorial-question-answer{
  color:#FFFFFF;
  font-weight:600;
  font-size:18px;
}

.tutorial-questions-container{
  width:100%;
}

.tutorial-question-container-answer{
  width:100%;
  color:#FFFFFF;
  font-weight:600;
  font-size:18px;
}

.tutorial-question-container{
  cursor:pointer;
  width:100%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  height:60px;
}

.tutorial-question-answer-outer-container{
  width:100%;
  min-height:60px;
  border-bottom: 1px solid rgba(255, 255, 255, 1);
  color:white;
  font-size:18px;
  padding-bottom:20px;
  padding-top:20px
}

.tutorial-answer{
  margin-top:20px;
}


.tutorial-question-answer-container{
  cursor:pointer;
  width:100%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
}

.tutorial-page-title{
  font-family: Gobold High Bold;
  font-size:42px;
  color:var(--bright-green);
  margin-bottom:20px;
}

.tutorial-tip-container{
  font-size:18px;
  padding: 24px 16px;
  position: relative;
  background: rgba(29, 43, 57, 0.5);
  border: 2px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  border-radius: 14px;
  display:flex;
  align-items:center;
  margin-bottom:30px;
  color:white;
}

.tutorial-right-content{
  width:100%;
  padding: 32px 24px;
  background: #1D2B39;
  border: 2px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  border-radius: 14px;
  display:flex;
  flex-direction:column;
  align-items:center;
}

.tutorial-right-title{
  font-size:24px;
  font-weight:700;
  color:#FFFFFF;
  margin-bottom:30px;
}

.tutorial-right-img{
  width:65%;
  margin-bottom:30px;
}


.tutorial-right-text{
  color:white;
  font-size:18px;
  font-weight:400;
}
