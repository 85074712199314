.mint-footer{
  width:100%;
  height:100px;
  display:flex;
  align-items:center;
  justify-content:center;
}

.mint-footer a{
  text-decoration:none;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  margin-left:64px;
}


.mint-footer a:hover{
  color:var(--bright-green);
}
