.premint-ordering-content{
  display:flex;
  align-items:center;
  width:100%;
}

.premint-ordering-img{
  width: 536px;
  height: 536px;
}

.premint-ordering-left{
  width:536px;
  height:100%;
}

.premint-ordering-title{
  font-family: 'Haettenschweiler';
  font-style: normal;
  font-weight: 500;
  font-size: 100px;
  line-height: 80%;
  color: #FFFFFF;
  margin-top:24px;
  margin-bottom:24px;
}

.premint-ordering-blurb{
  margin-top:20px;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
}

.premint-ordering-button-container{
  margin-top:20px;
  display:flex;
}

.premint-ordering-button{
  height: 59px;
  border: 2px dashed #39F376;
  box-sizing: border-box;
  border-radius: 10px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #39F376;
  margin-bottom:20px;
  cursor:pointer;
  width:48%;
}

.premint-ordering-button-complete{
  background: #114923 !important;
  border: 2px solid #114923;
}

.premint-progress-bar-text-container{
  display:flex;
  align-items:center;
  justify-content:space-between;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  color: #FFFFFF;
}

.premint-progress-bar{
  height:10px;
  background-color:white;
  border-radius:32px;
  position:relative;
}

.premint-progress-bar-green{
  position:relative;
  background-color:#39F376;
  height:10px;
  left:0px;
  top:0px;
  position:absolute;
  z-index:99;
}

.premint-progres-bar-wrapper{
  position:relative;
}

.zero-bar{
  border-top-left-radius:32px;
  border-bottom-left-radius:32px;
  width:6%;
}

.fifty-bar{
  border-top-left-radius:32px;
  border-bottom-left-radius:32px;
  width:50%;
}

.hundred-bar{
  border-radius:32px;
  width:100%;
}

.hngr-login-modal-title{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  color: #FFFFFF;
}

.hngr-login-modal-content{
  width:430px !important;
  background-color:black !important;
  border-radius:35px !important;
  border: 2px solid #39F376;
}

.phantom-modal-content{
  width:430px !important;
  padding:56px !important;
  justify-content:flex-start !important;
  box-sizing: border-box;
}

.phantom-modal-button{
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  text-decoration:none;
  cursor:pointer;
  padding: 0px 24px;
  height: 52px;
  background: #39F376;
  box-shadow: 2px 4px 0px #079E38;
  border-radius: 30px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;
  text-align: center;
  color: #000000;
}

.phantom-modal-button-black{
  width:100%;
  margin-top:24px;
  padding: 0px 24px;
  height: 52px;
  background: #000000;
  border: 2px solid #39F376;
  box-sizing: border-box;
  border-radius: 30px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color:#39F376;

}

.phantom-modal-title{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  align-items: center;
  color: #FFFFFF;
  width:100%;
  margin-top:24px;
  margin-bottom:16px;
}

.phantom-modal-blurb{
  margin-bottom:24px;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

.hngr-login-modal-background{
  background: rgba(355,355,355,.5) !important;
}

.hngr-login-modal-blurb{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  text-align:center;
  margin-top:15px;
  margin-bottom:30px;
  width:300px;
}

.login-circle{
  cursor:pointer;
  width:61px;
  height:61px;
  display:flex;
  align-items:center;
  justify-content:center;
  box-sizing:border-box;
  border-radius:62px;
}

.oauth-row{
  width:100%;
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:230px;
}

.or-row{
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:270px;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  margin-top:35px;
  margin-bottom:35px;
}

.or-left, .or-right{
  width:calc(135px - 20px);
  height:1px;
  background-color:white;
}

.signin-with-email{
  text-align:center;
  cursor:pointer;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-decoration-line: underline;
  color: #FFFFFF;
}


.signin-with-email:hover{
  color:blue;
} 

.login-input{
  width: 359px;
  height: 54px;
  background: #1E1E1E;
  border-radius: 7px;
  box-sizing:border-box;
  padding-left:10px;
  font-family: 'Space Mono';
  font-style: normal;
  background: #323232;
  font-weight: 400;
  font-size: 16px;
  color:white;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.premint-wallet-container .wallet-adapter-button-trigger{
  cursor:pointer;
  box-shadow:none !important;
  background-color:black;
  padding: 0px 16px;
  height: 39px;
  border: 2px solid #39F376;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #39F376;


}

.premint-wallet-container  .wallet-adapter-button-trigger:hover{
  background: black !important;
}

.premint-outer-container{
  display:flex;
  align-items:center;
  justify-content:center;
  min-height:calc(100vh - 100px);
}

.premint-right-tags{
  display:flex;
}

.premint-tag{
  height:59px;
  background: #114923;
  border-radius: 10px;
  padding:0px 24px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.premint-tag-green{
  background: #114923;
  color:#39F376;
}

.premint-tag-grey{
  background: #4C4C4C;
  color:white;
}

.premint-green-subtitle{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #39F376;
}

.premint-requirement{
  display:flex;
  align-items:center;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
}

.premint-requirement svg, .premint-requirement img{
  margin-right:15px;
  width:24px;
  height:24px;
}

.premint-requirement-box{
  display:flex;
  box-sizing: border-box;
  border-radius: 10px;
  height:60px;
  border: 1px solid #BBBBBB;
  align-items:center;
  justify-content:space-between;
  padding:0px 20px;
}

.premint-requirement-box-left{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}

.premint-requirement-box-connect{
  cursor:pointer;
  display:flex;
  align-items:center;
  justify-content:center;
  padding: 0px 16px;
  height: 39px;
  border: 2px solid #39F376;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #39F376;
}

.premint-requirement-box-left{
  height:100%;
  display:flex;
  align-items:center;
}

.premint-requirement-box-left svg{
  margin-right:15px;
}

.premint-requirement-box-connected{
  background: #114923 !important;
  border-color:  #114923 !important;
}

.premint-requirement-box-fail{
  background: rgba(255, 0, 0, 0.2) !important;
  border: 1px solid #FF0000 !important;
  color: #FF0000 !important;
}

.premint-requirement-box-left-fail{
  color: #FF0000 !important;
}

.premint-requirement-box-red-order{
  white-space:nowrap;
  text-decoration:none;
  height: 39px;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid #FF0000;
  box-sizing: border-box;
  border-radius: 10px;
  padding:0px 16px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */
  display: flex;
  justify-content:center;
  align-items: center;
  color: #FF0000;
}

.premint-requirement-box-left-connected{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #39F376 !important;
}

.premint-requirement-box-connected-button{
  border: 2px solid #39F376;
  box-sizing: border-box;
  border-radius: 30px;
  padding:0px 16px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #39F376;
  display:flex;
  align-items:center;
  justify-content:center;
  height:40px;
}

.premint-ordering-no-orders{
  background: rgba(255, 255, 255, 0.25) !important;
}


.premint-ordering-no-orders *{
  fill:white !important;
  stroke:white !important;
  color:white !important;
}


.premint-ordering-no-orders .premint-requirement-box-connected-button{
  border-color:white !important;
}

.connected-x{
  margin-left:10px;
  cursor:pointer;
}

.connected-x:hover{
  fill:red;
  stroke:red;
}

.login-input-label{
  text-align:left;
  width:359px;
  margin-bottom:9px;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
}

.forgot-password-sign-in-row{
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:359px;
}

.forgot-password{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  cursor:pointer;
  text-decoration:none;
}

.forgot-password:hover{
  color:blue;
}

.signin-email-button{
  cursor:pointer;
  display:flex;
  align-items:center;
  justify-content:center;
  background: #39F376;
  box-shadow: 2px 4px 0px #079E38;
  border-radius: 30px;
  padding:0px 40px;
  height:59px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}

.eligible-button-disabled{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  width: 301px;
  height: 59px;
  background: #BBBBBB;
  box-shadow: 2px 4px 0px #6F6F6F;
  border-radius: 30px;
  cursor:not-allowed;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  margin-top:35px;
}

.eligible-button-enabled{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  height: 59px;
  background: #39F376;
  box-shadow: 2px 4px 0px #079E38;
  border-radius: 30px;
  cursor:pointer;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  margin-top:35px;
  text-decoration:none;
}

.premint-black-button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  height: 59px;
  color: #39F376;
  border: 2px solid #39F376;
  border-radius: 30px;
  cursor:pointer;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin-top:35px;
  text-decoration:none;
}

.eligible-button-enabled:hover{
  text-decoration:none;
}

.premint-blurb{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-top:25px;
  margin-bottom:25px;
}

.premint-green-button{

}

.premint-requirement-box  .wallet-adapter-button-start-icon{
  display:none;
 }


.premint-requirement-box-unconnected{
  cursor:pointer;
}


.premint-requirement-box-unconnected:hover{
  background: rgba(255, 255, 255, 0.25);
}

.premint-requirement-box-unconnected:hover .wallet-adapter-button{
  background: rgba(255, 255, 255, 0.25) !important;
}

.premint-bottom-status{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 632px;
  height: 59px;
  background: #4C4C4C;
  border-radius: 10px;
  width:100%;
  margin-top:20px;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
}


.phantom-modal-bottom-section{
  margin-top:24px;
}

.phantom-modal-bottom-section-text{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}

.phantom-modal-bottom-section-link *{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #FFFFFF;
}
