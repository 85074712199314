.mint-page-landing-blrb{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom:24px;
  margin-top:24px;
  cursor:pointer;
}

.mint-page-landing-blrb a{
  text-decoration:none;
  cursor:pointer;
  color: #39F376;
}


.mint-page-landing-blrb a:hover{
  text-decoration:underline;
}

.cooking-nft-content{
  height:calc(100vh - 100px);
  background-color:black;
  display:flex;
  align-items:center;
  justify-content:center;
  width:100%;
}

.cooking-green-text{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #39F376;
}

.mint-error{
  box-sizing: border-box;
  position: absolute;
  width: 536px;
  height: 54px;
  left: 0px;
  bottom: 50px;
  background: #FFE4E4;
  border: 1px solid #FF0000;
  border-radius: 10px;
  display:flex;
  align-items:center;
  padding:0px 14px;
  display: flex;
  align-items: center;
  color: #FF0000;
}

.mint-error svg{
  margin-right:19px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}


.green-connect-button{
  display:flex;
  align-items:center;
  justify-content:center;
  padding-left:20px;
  padding-right:20px;
  height: 59px;
  background: #39F376;
  box-shadow: 2px 4px 0px #079E38;
  border-radius: 30px;
  box-sizing:border-box;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  cursor:pointer;
}


.green-connect-button:hover{
  background: #5AFF90;
  box-shadow: 2px 4px 0px #079E38;
}


.mint-centered-tag-row{
  display:flex;
  align-items:center;
  justify-content:center;
}

.mint-now-live{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #39F376;
}

.connect-to-continue{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color:white;
}


.mark-your-calendar{
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom:24px;
}
