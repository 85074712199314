.apply-content{
  width:100%;
  padding-top:20px;
  min-height:100vh;
  background-color:black;
  display:flex;
  flex-direction:column;
  align-items:center;
}

.apply-header{
  color:white;
  font-family:Gobold High Bold;
  font-size:130px;
  margin-top:30px;
  margin-bottom:30px;
}

.apply-input-label{
  color:var(--bright-green);
  font-size:12px;
  margin-left:30px;
  margin-bottom:10px;
}

.apply-input{
  width:100%;;
  height:75px;
  border-width:0px;
  border-radius:50px;
  font-size:16px;
  background-color:white;
  padding-left:30px;
  box-sizing:border-box;
  border:3px solid white; 
}


.apply-input:hover{
  background-color:grey;
  border:3px solid grey; 
}

.apply-input:focus{
  outline:none;
  background-color:white;
  border:3px solid var(--bright-green);
}

.apply-input-section{
  width:700px;
  margin-bottom:50px;
}

.apply-textarea{
  height:150px;
  width:100%;
  padding-top:30px;
}
