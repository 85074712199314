.progress-bar-container{
  width:100%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  background-color:white;
  height:80px;
  border-radius:10px;
  padding-bottom:10px;
}

.progress-bar-circle-step-container{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  position:relative;
  width:40px;
}

.progress-bar-circle{
  width:40px;
  height:40px;
  border-radius:40px;
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:#75c566;
  border:3px solid #75c566;
  box-sizing:border-box;
  font-family:Arial, sans-serif;
  color:white;
  font-weight:900;
  font-size:23px;
}

.progress-bar-circle-future{
  color:#d8d8d8;
  background-color:white;
  border:3px solid #d8d8d8;
  box-sizing:border-box;
}

.progress-bar-circle svg{
  width:20px;
  height:20px;
  fill:white;
}

.progress-bar-circle-step-page{
  position:absolute;
  bottom:-15px;
  white-space:nowrap;
}

.progress-bar-line{
  background-color:#75c566;
  height:6px;
  width:11%;
}

.progress-bar-line-grey{
  background-color:#d8d8d8;
}

.progress-bar-circle-step-page-future{
  color:#d8d8d8;
}
