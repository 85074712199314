html, body, #root{
  width:100%;
  height:100%;
  margin:0px;
  padding:0px;
  font-family:OCR A Extended;
}

input{
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 0px solid white;
  box-shadow: none;
}

.button{
  display:inline-flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  height:49px;
  background-color: var(--bright-green);
  cursor:pointer;
  border-radius: 30px;
  padding-left:16px;
  padding-right:16px;
  font-size:16px;
  font-weight:400;
  font-family:OCR A Extended;
  color:black;
}

.button-text-only{
  padding-left:30px;
  padding-right:30px;
  text-align:center;
}

.button:hover{
  background-color:white;
}


.button-small{
  height:35px;
  font-size:14px;
}

.button div{
  margin-right:5px;
}

.button svg{
  height:16px;
  margin-left:5px;
}

.page{
  width:100%;
  min-height:100vh;
  background-color:black;
  position:relative;
  padding-bottom:150px;
  box-sizing:border-box;
}

.content{
  width:100%;
  padding-left:110px;
  padding-right:110px;
  box-sizing:border-box;
}

.space-between-top{
  display:flex;
  flex-direction:row;
  align-items:top;
  justify-content:space-between;
  width:100%;
}

.space-between{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  width:100%;
}

.wallet-adapter-button-trigger{
}

.wallet-adapter-button-start-icon{
}

.page .wallet-adapter-button {
  padding: 0px !important;
}

.slider {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
}

.slide-in {
    animation: slide-in 0.9s forwards;
    -webkit-animation: slide-in 0.9s forwards;
}

.slide-out {
    animation: slide-out 0.9s forwards;
    -webkit-animation: slide-out 0.9s forwards;
}
    
@keyframes slide-in {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
}
    
@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(-100%); }
}


@font-face {
  font-family:OCR A Extended;
  src: url("fonts/ocraext.ttf") format("truetype");
}

@font-face {
  font-family:Gobold High Bold;
  src: url("fonts/Gobold_High_Bold.ttf") format("truetype");
}

@font-face{
  font-family: Haettenschweiler;
  src: url("fonts/Haettenschweiler-Regular.ttf") format("truetype");
}

