.modal-background{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: hidden;
  background: rgba(0,0,0,.5);
}

.modal-content{
  position:relative;
  max-width:70vw;
  padding: 40px;
  background: #08151B;
  border-radius: 40px;
  display:flex;
  flex-direction:column;
  align-items:center;
}

.modal-title{
  font-family: Gobold High Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 80px;
  line-height: 101px;
  color: #FFFFFF;
  margin-bottom:40px;
}

.modal-text{
  font-family: OCR A Extended;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
}

.apply-modal-phone{
  font-family: OCR A Extended;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 33px;
  color: #34EC72;
  margin-top:25px;
  margin-bottom:25px;
}

.apply-modal-code-input{
  background: #FFFFFF;
  border-radius: 50px;
  width:100%;;
  height:75px;
  border-width:0px;
  border-radius:50px;
  background-color:white;
  box-sizing:border-box;
  border:3px solid white;
  font-size: 52px;
  line-height: 54px;
  text-align: center;
  color: #6B6B6B;
  width:300px;

}

.apply-model-code-input:focus{
  background-color:white;
}


.modal-submit-button{
  background-color:var(--bright-green);
  color:black;
  font-size: 18px;
  display:flex;
  align-items:center;
  justify-content:center;
  height:50px;
  border-radius:30px;
  margin-top:20px;
  margin-bottom:20px;
  width:300px;
  cursor:pointer;
}

.apply-modal-resend{
  font-family: OCR A Extended;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;
  text-decoration-line: underline;

  color: #FFFFFF;
}

.modal-x-close{
  position:absolute;
  fill:white;
  width:16px;
  height:16px;
  cursor:pointer;
  top:20px;
  right:20px;
}
